import type { PaymentHistory, PaymentRecord, RewardTotals, UserRewards } from "../user-rewards";
import { Program } from "../program";
import { pickBy } from "lodash-es";
import { Leaderboard } from "../leaderboard";
import { isBefore } from "date-fns";
import { Team } from "../../team";
import { uniqBy } from "lodash-es";
import { ecoToCurrency } from "../../quest";
import { Result } from "../../utils/result";

export interface RewardsRepository {
  getUserRewards: (userId: string, profileId?: string) => Promise<UserRewards[]>;
  getRewardsByProfileId: (profileId: string) => Promise<UserRewards[]>;
  claimReward: (
    userId: string,
    address: string,
    userRewardsRollupId: string,
    profileId?: string,
  ) => Promise<Result<{ paymentRecordId: string }, string>>;
  getPaymentRecordById: (paymentRecordId: string) => Promise<PaymentRecord | null>;
  getPaymentHistory: (userId: string, profileId?: string) => Promise<PaymentHistory[]>;
  getPrograms: () => Promise<Program[]>;
  getProgramById: (programId: string) => Promise<Program | null>;
  getRewardTotals: (userId?: string, profileId?: string) => Promise<RewardTotals>;
}

export interface RewardsLeaderboardRepository {
  getLeaderboard: (program: Program) => Promise<Leaderboard[]>;
}

export class RewardsService {
  constructor(
    private readonly data: RewardsRepository,
    private readonly leaderboard: RewardsLeaderboardRepository,
  ) {}

  async getLeaderboard(program: Program): Promise<Leaderboard[]> {
    return this.leaderboard.getLeaderboard(program);
  }

  async getPaymentRecordById(paymentRecordId: string): Promise<PaymentRecord | null> {
    return this.data.getPaymentRecordById(paymentRecordId);
  }

  async getUserRewards(userId: string, profileId?: string): Promise<UserRewards[]> {
    return this.data.getUserRewards(userId, profileId);
  }

  async getUserAndTeamsRewards(userId: string, teams: Team[]): Promise<UserRewards[]> {
    const teamProfileIds = teams.map((team) => team.profileId);
    const teamRewardsP = teamProfileIds.map((id) => this.getRewardsByProfileId(id));
    const userRewardsP = this.getUserRewards(userId);
    const [userRewards, teamRewards] = await Promise.all([userRewardsP, Promise.all(teamRewardsP)]);
    const uniqueRewards = uniqBy([...userRewards, ...teamRewards.flat()], (reward) => reward.id);
    return uniqueRewards;
  }

  async getRewardsByProfileId(profileId: string): Promise<UserRewards[]> {
    return this.data.getRewardsByProfileId(profileId);
  }

  async claimReward(userId: string, address: string, userRewardsRollupId: string) {
    return await this.data.claimReward(userId, address, userRewardsRollupId);
  }

  async getPrograms(): Promise<Program[]> {
    return this.data.getPrograms();
  }

  async getActivePrograms(): Promise<Program[]> {
    const programs = await this.getPrograms();
    return programs.filter(RewardsService.isProgramActive);
  }

  async getPaymentHistory(userId: string, profileId?: string): Promise<PaymentHistory[]> {
    return this.data.getPaymentHistory(userId, profileId);
  }

  async getUserAndTeamsPaymentHistory(userId: string, teams: Team[]): Promise<PaymentHistory[]> {
    const teamProfileIds = teams.map((team) => team.profileId);
    const teamPaymentsP = teamProfileIds.map((id) => this.data.getPaymentHistory(userId, id));
    const userPaymentsP = this.data.getPaymentHistory(userId);
    const [userPayments, teamPayments] = await Promise.all([userPaymentsP, Promise.all(teamPaymentsP)]);
    return [...userPayments, ...teamPayments.flat()];
  }

  async getRewardTotals(userId?: string, profileId?: string): Promise<RewardTotals> {
    if (!userId && !profileId) throw new Error("userId or profileId must be provided");
    const totals = await this.data.getRewardTotals(userId, profileId);
    const perChainEarnings = pickBy(totals.lifetimeRewards, (_, c) => c !== "ISO");
    return {
      ...totals,
      lifetimeRewards: perChainEarnings,
    };
  }

  async getEcosystemRewardTotals(userId: string, profileId: string, ecosystem: string) {
    const rewardTotals = await this.getRewardTotals(userId, profileId);
    const currency = ecoToCurrency[ecosystem];
    if (!currency) throw new Error(`No currency found for ecosystem ${ecosystem}`);
    const earned = rewardTotals.lifetimeRewards[ecosystem]?.[currency];
    const pending = earned ? earned.tokenEarned - earned.tokenClaimed : 0;
    const total = earned?.tokenEarned ?? 0;
    return {
      total,
      pending,
      currency,
    };
  }

  async getProgramById(programId: string): Promise<Program | null> {
    return this.data.getProgramById(programId);
  }

  static isProgramActive(program: Program): boolean {
    const currentTime = new Date();
    if (program.programConfig.startsAt && isBefore(currentTime, program.programConfig.startsAt)) {
      return false;
    }
    // If they don't have an endAt by now, let's filter it out. Staging is out of hand.
    if (!program.programConfig.endsAt || isBefore(program.programConfig.endsAt, currentTime)) {
      return false;
    }
    return true;
  }

  static generateBlockExplorerLink(paymentRecord: PaymentRecord): string | null {
    switch (paymentRecord.chain) {
      case "ethereum": {
        if (paymentRecord.network === "testnet") {
          return `https://goerli.etherscan.io/tx/${paymentRecord.txID}`;
        }
        return `https://etherscan.io/tx/${paymentRecord.txID}`;
      }
      case "polygon": {
        return `https://polygonscan.com/tx/${paymentRecord.txID}`;
      }
      case "near": {
        return `https://nearblocks.io/txns/${paymentRecord.txID}`;
      }
      case "solana": {
        return `https://solscan.io/tx/${paymentRecord.txID}`;
      }
      case "avalanche": {
        return `https://snowtrace.io/tx/${paymentRecord.txID}`;
      }
      case "flow": {
        return `https://flowdiver.io/transaction/${paymentRecord.txID}`;
      }
      case "axelar": {
        return `https://axelarscan.io/tx/${paymentRecord.txID}`;
      }
      case "sei": {
        return `https://www.seiscan.app/pacific-1/txs/${paymentRecord.txID}`;
      }
      case "aptos": {
        return `https://explorer.aptoslabs.com/txn/${paymentRecord.txID}?network=mainnet`;
      }
      case "blast": {
        return `https://blastscan.io/tx/${paymentRecord.txID}`;
      }
      default:
        return null;
    }
  }

  static isClaimed(userReward: UserRewards): boolean {
    if (userReward.tokenClaimed < userReward.tokenEarned) return false;
    return true;
  }

  static isPaymentForReward(paymentRecord: PaymentRecord, userReward: UserRewards): boolean {
    if (paymentRecord.rewardSourceId !== userReward.rewardSourceId) return false;
    if (paymentRecord.chain !== userReward.chain) return false;
    return true;
  }

  static sortPrograms(programs: Program[]): Program[] {
    const priorityOrder: { [key: string]: number } = {
      Gold: 1,
      Silver: 2,
      Bronze: 3,
    };
    const getPriority = (name: string): number => {
      const programType = Object.keys(priorityOrder).find((type) => name.includes(type));
      return programType && priorityOrder[programType] ? priorityOrder[programType]! : Infinity;
    };

    const sortedPrograms = programs.sort((a, b) => {
      const priorityA = getPriority(a.programConfig.name);
      const priorityB = getPriority(b.programConfig.name);

      return priorityA - priorityB;
    });

    return sortedPrograms;
  }
}
